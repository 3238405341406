import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import { default as Old2005BlogPage, doNothingAnchor } from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—November 2007" />
	<Old2005BlogPage>
		<h2>November 2007</h2>



		<h3 className="blogdate">
		Monday, November 12, 2007
		</h3>






		<h4 className="blogitemtitle">
		Podcast: Guy Fawkes Day
		</h4>





			<div className="blogitembody">
			I went for a walk through the suburb of Island Bay (Wellington, New Zealand) in the evening of Guy Fawkes Day (November 6, 2007).  Normally Island Bay is a peaceful and quiet suburb, but on that evening it was a veritable war zone!<br /><ul><li><a href="/" onClick={doNothingAnchor}>(removed)</a><br /></li></ul>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 4:55 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Tuesday, November 06, 2007
		</h3>






		<h4 className="blogitemtitle">
		The Tide is Rising
		</h4>





			<div className="blogitembody">
			It was December 6, 2006, at the Paramount Theatre in Wellington... a nearly-full house of movie spectators... it was the grand premier... it was the one-and-only showing... are you excited yet?<br /><br />Oh stop, it's not that big of a deal!  There were three short films premiering that night, the final product of the 2006 graduating class of The Film School.  Actually, it was a bit exciting - I wrote the soundtrack for the first film, titled "The Tide is Rising"!<br /><br />I sat in a rear-ish seat (it was quite full, several hundred peeps) and took in the moment - the glory - and as the film began, I immediately thought, "Wow, I wish we'd recorded the soundtrack better!"  And, "Not bad for a week-end's work!"  Alas, it was cool to see a movie in a grand theatre, knowing that I wrote the soundtrack for the film.... with my friend Vorn... he wrote the bass line for the scene where Jenny is walking along the street.<br /><br />The film's editor has made it available on YouTube, so you can check it out!<br /><ul><li><a href="http://www.youtube.com/watch?v=Rg-zreJYbvU">The Tide is Rising - Part 1</a></li><li><a href="http://www.youtube.com/watch?v=gnNQob-E1BI">The Tide is Rising - Part 2</a><br /></li></ul>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 6:42 PM &nbsp;
			</div>




		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
